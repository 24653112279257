.Search {
	background-color: whitesmoke;
	box-sizing: border-box;
	text-align: center;
	margin: 0;
	padding: 0;
}

#searchBox {
	border: 1px solid black;
	color: black;
	font-size: 1em;
	height: 100px;
	width: 100%;
	box-sizing: border-box;
}

.searchForm {
	
	min-height: 80px;
	text-align: center;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid #eee;
	align-items: center;
}

.searchBar {
	flex-grow: 1;
	padding: 3px 20px;
	margin: 10px;
	border-radius: 0.25rem;
	outline: none;
	text-align: center;
}

#resultTable {
	display: 'block';
	text-align: 'left';
	width: '100%';
}

#narrow {
	width: 150px;
}

#wide {
	width: 180px;
}
