.Main {
	background-color: whitesmoke;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

main {
	/* border: 1px solid black; */
	color: black;
	font-size: 1em;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	/* border: 2px solid red; */
}

.box-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	/* border: 2px solid purple; */
	/* position: relative;  */
	/* background-color: aqua; */
}

#boxTop {
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	min-height: 80px;
	/* border: 2px solid red; */
	margin: 5px 0px;
}

#boxTopLeft {
	text-align: left;
	padding-left: 10px;
	width: 50%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	margin-right: 10px;
	/* border: 2px solid purple; */

}

#boxTopRight {
	text-align: left;
	padding-left: 10px;
	width: 50%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	/* border: 2px solid teal; */
}

#boxMain {
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	margin: 5px 0px;
	height: 70%;
	/* border: 2px solid royalblue; */
}

#boxMainLeft {
	text-align: left;
	padding-left: 10px;
	width: 50%;
	/* height: 100%; */
	height: 50%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	margin-right: 10px;
	/* border: 2px solid purple; */
}

#boxMainRight {
	text-align: left;
	padding-left: 10px;
	width: 50%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	/* border: 2px solid teal; */
}

#boxBottom {
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	margin: 5px 0px;
	width: 100%;
	/* border: 2px solid green; */
	height: 10%;
}

#label {
	border: 1px solid black;
	font-size: 1em;
	font-weight: bold;
	padding: 5px;
	border-radius: 10px;
	width: 200px;
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

#input {
	font-size: 1em;
	padding: 5px;
	border-radius: 10px;
	width: 400px;
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(211, 211, 211, 0.521);
}

#inputBig {
	font-size: 1.5em;
	padding: 5px;
	border-radius: 10px;
	width: 400px;
	height: 200px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	white-space: pre-wrap; /* or pre-line */
	background-color: rgba(211, 211, 211, 0.521);
}

#insideBox {
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	margin-right: 10px;
	/* border: 2px solid purple; */
}

#insideBoxBig {
	text-align: left;
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	margin-right: 10px;
	padding-top: 20px;
}

#wrapper {
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	margin-right: 10px;
	padding: 5px 0px;
	/* border: 2px solid purple; */
}