.Footer {
	background-color: whitesmoke;
	box-sizing: border-box;
	text-align: center;
	margin: 0;
	padding: 0;
}

footer {
	/* border: 1px solid black; */
	color: red;
	font-size: 1em;
	height: 100px;
	width: 100%;
	box-sizing: border-box;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
}
