.Header {
  background-color: whitesmoke;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  }

header {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding-left: 20px;
  }

  header {
    border: 1px solid black;
    color: black;
    font-size: 2em;
    height: 100px;
    width: 100%;
    box-sizing: border-box;
  }