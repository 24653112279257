.container {
	display: grid;
	justify-items: center;
	align-items: center;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	width: 100%;
	height: 100vh;
}

.page-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.4fr 0.4fr 0.4fr 3.7fr 0.7fr 0.4fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 1 / 1 / 2 / 2;
	width: 99%;
	height: 99%;
}

.header-container {
	border-radius: 10px;
	border: 2px solid #800000;
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
}

.searchbox-container {
	border-radius: 10px;
	border: 2px solid #438000;
	grid-area: 2 / 1 / 3 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
}

.main-top-container {
	display: grid;
	grid-template-columns: 0.5fr 1.5fr;
	grid-template-rows: 1fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 3 / 1 / 4 / 2;
}

.main-top-left-container {
	border-radius: 10px;
	border: 2px solid #475b80;
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
}

.main-top-right-container {
	border-radius: 10px;
	border: 2px solid #475b80;
	grid-area: 1 / 2 / 2 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
}

.main-mid-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 4 / 1 / 5 / 2;
}

.main-mid-left-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 3.5fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 1 / 1 / 2 / 2;
}

.label-claimant {
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.text-claimant {
	grid-area: 1 / 2 / 2 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.label-clientref {
	grid-area: 2 / 1 / 3 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.text-clientref {
	grid-area: 2 / 2 / 3 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.label-defendant {
	grid-area: 3 / 1 / 4 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.text-defendant {
	grid-area: 3 / 2 / 4 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.label-employer {
	grid-area: 4 / 1 / 5 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.text-employer {
	grid-area: 4 / 2 / 5 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.label-matter {
	grid-area: 5 / 1 / 6 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.text-matter {
	grid-area: 5 / 2 / 6 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #318042;
}

.main-mid-right-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 0.33fr 1.4fr 1.8fr 0.5fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 1 / 2 / 2 / 3;
}

.label-comments {
	grid-area: 1 / 1 / 2 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #772280;
}

.text-comments {
	grid-area: 2 / 1 / 3 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #772280;
}

.label-status {
	grid-area: 4 / 1 / 5 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #772280;
}

.text-status {
	grid-area: 4 / 2 / 5 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #772280;
}

.main-bot-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 5 / 1 / 6 / 2;
}

.main-bot-left-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1.2fr 0.9fr 0.9fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 1 / 1 / 2 / 2;
}

.heading-modified {
	grid-area: 1 / 1 / 2 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #803939;
}

.label-modified-date {
	grid-area: 2 / 1 / 3 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #803939;
}

.text-modified-date {
	grid-area: 2 / 2 / 3 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #803939;
}

.label-modified-name {
	grid-area: 3 / 1 / 4 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #803939;
}

.text-modified-name {
	grid-area: 3 / 2 / 4 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #803939;
}

.main-bot-right-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1.2fr 0.9fr 0.9fr;
	gap: 5px 5px;
	grid-auto-flow: row;
	grid-area: 1 / 2 / 2 / 3;
}

.heading-created {
	grid-area: 1 / 1 / 2 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #0db2b4;
}

.label-created-date {
	grid-area: 2 / 1 / 3 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #0db2b4;
}

.text-created-date {
	grid-area: 2 / 2 / 3 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #0db2b4;
}

.label-created-name {
	grid-area: 3 / 1 / 4 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #0db2b4;
}

.text-created-name {
	grid-area: 3 / 2 / 4 / 3;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid #0db2b4;
}

.footer-container {
	border-radius: 10px;
	border: 2px solid #18b429;
	grid-area: 6 / 1 / 7 / 2;
	display: grid;
	justify-items: center;
	align-items: center;
}
