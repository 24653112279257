.App {
	display: flex;
	flex-direction: column;
	height: 98vh; /* 100% of the viewport height */
	margin: 10px;
	padding: 0;
}

.LoadErr {
	/* border: 1px solid black; */
	color: black;
	font-size: 1.5em;
	height: 100%;
	width: 100% !important;
	box-sizing: border-box;
	text-align: center;
	padding-top: 20px;
}

hr.divider {
	margin: 0em;
	padding: 0em;
	border-width: 1px;
	border: solid;
	border-color: red;
}
